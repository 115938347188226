import { h } from "vue";
import { ElNotification } from "element-plus";

export default class NotifService {
  static displayErrorAlert(error: string): void {
    ElNotification({
      title: "ERROR",
      message: h("i", { style: "color: red" }, error),
      type: "error",
    });
  }
  static displaySuccessAlert(msg: string): void {
    ElNotification({
      title: "Success",
      message: h("i", { style: "color: green" }, msg),
      type: "success",
    });
  }
}

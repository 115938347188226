import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const setQueryToken = async (data: any) => {
  await store.dispatch(Actions.FETCH_USER_ACCESS_TOKEN, {
    data: data,
  });
};

export const setInfoUser = async (data: any) => {
  await store.dispatch(Actions.FETCH_INFO_USER, {
    data: data,
  });
};

export const setSubscribeAdmin = async (data: any) => {
  await store.dispatch(Actions.FETCH_SUBSCRIBE_ADMIN, {
    data: data,
  });
};

export const setLogin = async (data: any) => {
  await store.dispatch(Actions.FETCH_LOGIN, {
    data: data,
  });
};

export const setInviteUser = async (data: any) => {
  await store.dispatch(Actions.FETCH_INVITE_USER, {
    data: data,
  });
};

export const setUserDetails = async (data: any) => {
  await store.dispatch(Actions.SET_USERDETAILS_ACTIONS, {
    data: data,
  });
};

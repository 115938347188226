
import { defineComponent, ref, computed, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { setQueryToken, setInfoUser } from "@/core/helpers/user";
import { useRouter } from "vue-router";
import { clearError } from "@/core/helpers/Error";
import NotifService from "@/core/services/NotifService";
import useError from "@/core/composable/useError";
import useTranslate from "@/core/composable/useTranslate";

interface userForm {
  firstName: string;
  lastName: string;
  password: string;
  cpassword: string;
  title: string;
  skills: Array<string>;
}

export default defineComponent({
  name: "create-api-key-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const { translate } = useTranslate();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const user = computed(() => {
      return store.getters.getUser;
    });
    const subscribeMember = computed(() => {
      return store.getters.resultSubscribeUser;
    });

    onMounted(() => {
      setQueryToken(route.query.token);
    });
    const userForm = ref<userForm>({
      firstName: "",
      lastName: "",
      password: "",
      title: "",
      cpassword: "",
      skills: ["Html", "Css"],
    });

    const validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .required(`${translate("firstNameRequired")}`)
        .label("First Name"),
      lastName: Yup.string()
        .required(`${translate("lastNameRequired")}`)
        .label("Last Name"),
      password: Yup.string()
        .required()
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          `${translate("passwordRequired")}`
        )
        .label("Password"),
      cpassword: Yup.string()
        .min(8)
        .required()
        .oneOf(
          [Yup.ref("password"), null],
          `${translate("confirmPasswordRequired")}`
        )
        .label("Password Confirmation"),
      title: Yup.string()
        .required(`${translate("titleRequired")}`)
        .label("title"),
      skills: Yup.string()
        .required(`${translate("skillsRequired")}`)
        .label("Skills"),
    });

    const { getError } = useError();

    const submit = async () => {
      await setInfoUser(userForm.value);
      if (typeof subscribeMember.value.jwtTokens !== "undefined") {
        clearError();
        router.push({ name: "home" });
      } else {
        NotifService.displayErrorAlert(getError.value.message);
      }
    };

    return {
      userForm,
      validationSchema,
      submit,
      user,
    };
  },
});

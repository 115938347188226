import { computed } from "vue";
import { useStore } from "vuex";
export default function useError() {
  const store = useStore();
  const getError = computed(() => {
    return store.getters.getError;
  });
  return {
    getError,
  };
}
